<template lang="pug">
.login-page
  Register(
    v-if='showRegister',
    headerText='Sign up for free and start editing your design',
    @close-register='closeIframe'
  )
  Login(v-if='showLogin', @close-login='closeIframe')
  ForgotPassword(
    v-if='showForgotPassword',
    @close-forgot-password='closeIframe'
  )
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Register from '@/components/ProjectCanvas/PageOverlay/Register.vue';
import Login from '@/components/ProjectCanvas/PageOverlay/Login.vue';
import ForgotPassword from '@/components/ProjectCanvas/PageOverlay/ForgotPassword.vue';
import { isWindowEventListenerAllowed } from '@/assets/scripts/utilities';
import { iframeParentURL } from '@/assets/scripts/variables';

export default {
  components: {
    Register,
    Login,
    ForgotPassword,
  },
  computed: {
    ...mapState([
      'showRegister',
      'showLogin',
      'showForgotPassword',
    ]),
  },
  beforeMount() {
    document.body.classList.add('is-transparent');
    this.setShowLogin(true);
  },
  mounted() {
    window.addEventListener('message', (event) => {
      // IMPORTANT: check the origin of the data!
      console.log(
        'event origin login',
        event.origin,
        event.data,
        isWindowEventListenerAllowed(event),
      );
      // alert('isWindowEventListenerAllowed: ' + event.origin + '---' + isDevMode())
      if (isWindowEventListenerAllowed(event)) {
        // The data was sent from your site.
        // Data sent with postMessage is stored in event.data:
        // console.log(event.origin, event.data);
        if (event.data.register) {
          this.setShowLogin(false);
          this.setShowRegister(true);
          this.setShowForgotPassword(false);
        }

        if (event.data.login) {
          this.setShowLogin(true);
          this.setShowRegister(false);
          this.setShowForgotPassword(false);
        }
      }
    });
  },
  methods: {
    ...mapMutations([
      'setShowLogin',
      'setShowRegister',
      'setShowForgotPassword',
      'setIsLoggedIn',
    ]),
    closeIframe() {
      console.log('post message');
      window.parent.postMessage({ close: true }, iframeParentURL());
    },
  },
};
</script>

<style lang="scss">
@import '~normalize.css';
@import '~@/assets/icomoon/style.scss';

body.is-transparent {
  background: transparent;
}

.visuallyhidden,
.vh {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

body {
  background: #fff;
  font-weight: 500;
  scroll-behavior: smooth;
  font-family: $baseFont;

  * {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(230, 230, 230, 0.5);
    }

    &::-webkit-scrollbar-thumb {
      background: $timelineGrey;
      transition: background 0.25s ease-in-out;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken($timelineGrey, 5%);
    }
  }

  .crisp-client * {
    visibility: hidden !important;
    opacity: 0;
    pointer-events: none;
  }
}

button {
  border: 0;
  cursor: pointer;
  background: transparent;

  &:active,
  &:hover,
  &:focus {
    outline: 0;
    background: inherit;
  }
}

#app {
  font-family: $baseFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
</style>
